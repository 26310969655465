import firebase from 'firebase/app';
import  axios from 'axios'
import 'firebase/auth';

const config = {
    apiKey:process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID
};

let firebaseApp
if (!firebase.apps.length) {
    firebaseApp = firebase.initializeApp(config);
} else {
    firebaseApp = firebase.app(); // if already initialized, use that one
}

export const auth = firebase.auth();

// const googleProvider = new firebase.auth.GoogleAuthProvider();
// googleProvider.setCustomParameters({ prompt: 'select_account' });
// export const signInWithGoogle = () => {
//     auth.signInWithPopup(googleProvider).then(res => {
//         localStorage.setItem("isLogin", true);
//         localStorage.setItem("UserData", JSON.stringify(res.user.providerData[0]));
//         console.log(res.user.providerData[0]);
//         let data=res.user.providerData[0]
//         let userData={
//             "providerId":data.providerId,
//             "userToken":data.uid,
//             "displayName":data.displayName,
//             "photoURL":data.photoURL,
//              "userEmail":data.email,
//         }
//         var headers = {
//             "Content-Type": "application/json",
//             "Access-Control-Allow-Origin": "*",
//           };
//           var apiBaseUrl = `${process.env.REACT_APP_AWS_URL}/api/user`;
//         axios.post(apiBaseUrl, userData, {headers : headers}, {validateStatus : false})
//         .then(response => {
//             console.log(response)
//             return window.location.reload()
//         })
//         .catch(err => {
//             console.log(err);
//             return <Snackbar severity="error" message="Please Login Again" />
//         })
       
//     })
//     .catch(err => {
//         console.log(err.message);
//     })
// }
let provider = new firebase.auth.GithubAuthProvider();
    provider.addScope('repo');
    provider.setCustomParameters({
        'allow_signup': 'false'
      });
      
//github ligin
export const signInWithGithub = () => {
    
   return auth.signInWithPopup(provider).then(res => {
        
        var credential = res.credential;
        var additionalUserInfo = res.additionalUserInfo;
        var profileInfo = additionalUserInfo.profile;
        var gitToken = credential.accessToken;
        
        localStorage.setItem("isLogin", true);
        localStorage.setItem("UserData", JSON.stringify(res.user.providerData[0]));
        console.log(res.user.providerData[0]);
        var data=res.user.providerData[0]
        let userData={
            "providerId":data.providerId,
            "userToken":data.uid,
            "displayName":data.displayName,
            "photoURL":data.photoURL,
             "userEmail":data.email,
             "githubToken" : gitToken,
             "githubUsername" : additionalUserInfo.username,
             "gitProfileCreated" : profileInfo.created_at,
             "followers" : profileInfo.followers,
             "following" : profileInfo.following,
             "htmlUrl" : profileInfo.html_url,
             "publicRepoCount" : profileInfo.public_repos,
             "apiUrl" : profileInfo.url
        }
        var headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          };
          
          var body2 = {

          };
          
          var apiBaseUrl = `${process.env.REACT_APP_AWS_URL}/api/user`;
          
        axios.post(apiBaseUrl, userData, {headers : headers}, {validateStatus : false})
        .then(user => {
            let userFinal = JSON.parse(JSON.stringify(user));
            console.log("user data is", userFinal);
            localStorage.setItem("jwtToken", user.data.data);
            var headers2 = {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization" : userFinal.data.data
              };
            axios.put(`${process.env.REACT_APP_AWS_URL}/api/userRepo`,body2,{headers : headers2}, {validateStatus : false})
            .then(response => {
                
                return window.location.reload();
             
            })
            .catch(err => {
                console.log(err);
            return [];
            })
            
        })
        .catch(err => {
            console.log(err);
            return [];
        })
       
    })
    .catch(err => {
        return [];
    })
}

export default firebase;
