import React, {useEffect} from "react";
import clsx from "clsx";
import {
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import { Redirect } from 'react-router-dom'
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Footer from '../commons/Footer';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import 'firebase/auth';
import Avatar from '@material-ui/core/Avatar'
import { signInWithGoogle } from '../components/firebase/firebase'
import LoginPopUp from '../commons/LoginPopup'
import StarIcon from '@material-ui/icons/Star';



const drawerWidth = 240;


const StyledMenu = withStyles((theme) => ({
  paper: {
    border: '1px solid #d3d4d5',
    backgroundColor: theme.palette.primaryCol
  },
}))(Menu);


const StyledMenuItem = withStyles((theme) => ({

}))(MenuItem);


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",

  },
  grow: {
    flexGrow: 1,
    width: '100%'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),

  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  color: {
    color: theme.palette.primaryCol
  },
  icon: {
    marginRight: theme.spacing(2),
    height: '64px'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  button: {
    color: theme.palette.primaryCol,
    borderColor: theme.palette.primaryCol,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
  hidden: { display: "none" },
  buttonConsult : {
    color : theme.palette.primaryCol,
    borderColor : theme.palette.primaryCol,
    
  }
  
}));




function NavBar(props) {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const loginValue = localStorage.getItem("session-token") || null;
  const [openLogin, setOpenLogin] = React.useState(false);

  const [notificationArray, setNotificationArray] = React.useState([]);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  // Logout Button
  const handleLogout = () => {

    localStorage.removeItem('UserData');
    localStorage.removeItem('isLogin');
    localStorage.removeItem('jwtToken');
    let string = window.location.origin;
    window.open(string, "_self")
  }

  const handleLogin = () => {

    setOpenLogin(true)

  }

  useEffect(()=>{
    if (localStorage.getItem("isLogin")) {
      setOpenLogin(false)
    }
  },[openLogin])
 

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <StyledMenu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {!localStorage.getItem("isLogin") ? <StyledMenuItem onClick={handleLogin}>Profile</StyledMenuItem> :
        <StyledMenuItem onClick={handleLogout}>Log Out</StyledMenuItem>}
    </StyledMenu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  let renderMobileMenu;
  if (!loginValue) {
    renderMobileMenu =
      (
        <StyledMenu
          anchorEl={mobileMoreAnchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          id={mobileMenuId}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMobileMenuOpen}
          onClose={handleMobileMenuClose}
        >
          <StyledMenuItem>
            <Button variant="outlined" href="/login">
              Login
       </Button>
          </StyledMenuItem>
        </StyledMenu>
      );
  } else {
    renderMobileMenu = (
      <StyledMenu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >

        <StyledMenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </StyledMenuItem>

      </StyledMenu>
    )
  }




  return (
    <div className={classes.grow}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Link href="/" color="inherit">
            <img alt="" src={window.location.origin + '/images/techinstalogo.svg'} className={classes.icon} />
          </Link>

          <Link href="/" color="inherit">
            <Typography variant="h6" noWrap className={classes.color}>
              TechInsta
          </Typography>
          </Link>

         

          <div className={classes.grow} />
          <Link href="/consult">
            <Button variant="outlined" className={classes.buttonConsult} endIcon={<StarIcon />}>Sessions</Button>
          </Link>
          <LoginPopUp open={openLogin} setOpenLogin={setOpenLogin}/>

          <div className={classes.sectionDesktop}>

            {!localStorage.getItem("isLogin") ? <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleLogin}
              color={classes.color}
            >
              <AccountCircle />
            </IconButton> : <Avatar alt='profileImage' src={JSON.parse(localStorage.getItem("UserData"))?.photoURL} onClick={handleProfileMenuOpen} />}
          </div>

          <div className={classes.sectionMobile}>
          {!localStorage.getItem("isLogin") ? <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleLogin}
              color={classes.color}
            >
              <AccountCircle />
            </IconButton> : <Avatar alt='profileImage' src={JSON.parse(localStorage.getItem("UserData"))?.photoURL} onClick={handleProfileMenuOpen} />}
          </div>

        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.content}
        {renderMobileMenu}
        {renderMenu}
      </main>
      <Footer />
    </div>
  );
}



export default NavBar