import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UseDebounce from '../library/Debounce';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root : {
        display : 'flex',
        flexDirection : 'column',
        flex : 0.7,
        [theme.breakpoints.down("md")]: {
            flex : 1,
          },
        marginRight : theme.spacing(2)
    },
    root2 : {

    },
    input: {
        borderRadius: "40px",
        border : "2px solid",
        borderColor : theme.palette.primaryCol,
        width: "100%",
        paddingLeft : "20px",
        padding : "0",
        textAlign: "center",
        height: "40px",
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
      
        
        backgroundColor: "white",
      
      },
      input2 : {
            borderRadius: "40px",
            border : "2px solid",
            borderColor : theme.palette.primaryCol,
            width: "30vw",
            paddingLeft : "20px",
            padding : "0",
            textAlign: "center",
            height: "40px",
            margin: theme.spacing(1),
            flex: 1,
            [theme.breakpoints.down("sm")]: {
              height : "40px",
              width: "60vw",
            },
            backgroundColor: "white",
      },
      listitem : {
        width: "100%",
        
          backgroundColor : 'white'
      },
      listitem2 : {
        width: "100%",
        backgroundColor : theme.palette.secondary.light
      }


}))

function SearchField(props) {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = React.useState('');
  // State and setter for search results
  
  // State for search status (whether there is a pending API request)
  const [isSearching, setIsSearching] = React.useState(false);
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = UseDebounce(searchTerm, 500);

  React.useEffect(
    () => {
        
      // Make sure we have a value (user has entered something in input)
      if (debouncedSearchTerm) {
        // Set isSearching state
        setIsSearching(true);
        // Fire off our API call
        getGithubSearch(debouncedSearchTerm).then(results => {
          // Set back to false since request finished
          console.log("Results came in", results);
          setIsSearching(false);
          // Set results state
          props.setResults(results);
        });
      } else {
        props.setResults([]);
      }
    }, [debouncedSearchTerm]);


    const handleSearchChange =(result) => (event) => {
      event.preventDefault();
      props.setLink(result);
      props.setResults([]);
    }
    
    const handleChange = (event) => {
        props.setLink(event.target.value);
        setSearchTerm(event.target.value);
        props.setResults([]);
      }
    return (
        <div className={props.from === 'stepper' ? classes.root : classes.root2}>
        <TextField
        className={props.from === 'stepper' ? classes.input : classes.input2}
        placeholder="Search your repository"
        InputProps={{
          disableUnderline: true,
          shrink: true,
          "&::placeholder": {
            fontStyle: "italic",
            textAlign: "center",
          },
        }}
        value={props.link}
        onChange={handleChange}
      />
      {isSearching && <div className={props.from === 'stepper' ? classes.listitem : classes.listitem2}><Typography>Searching ...</Typography></div>}
                {props.results.map((result,index) => (
                  <ListItem button value={"item__"+index} content={Paper} className={props.from === 'stepper' ? classes.listitem : classes.listitem2} onClick={handleSearchChange(result)}>
                    <ListItemText primary={result} />
                  </ListItem> 
                ))}
      </div>
    )
}

async function getGithubSearch(search){
        
    var queryString = `string=${search}&size=5`
    
    console.log("Query string is", queryString);
     return fetch (
      `${process.env.REACT_APP_AWS_URL}/api/userRepo?${queryString}`,
      {
        method: 'GET',
        headers: new Headers({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Authorization" : localStorage.getItem("jwtToken") || ""
        })
      }).then(r => r.json())
      .then(r => r.data)
      .catch(err =>{
        console.log(err);
        return [];
      }
    )
    
  }
  

export default SearchField
