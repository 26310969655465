import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import clsx from 'clsx';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const useStyles = makeStyles((theme) => ({
    dialog: {
      
    backgroundColor:"#fff",
    
        
  },
  submitDiv : {
    display : 'flex',
      flexDirection : 'column',
      alignItems : 'center',
  },
  submit : {
      
      
      backgroundColor : theme.palette.primaryCol,
      color : 'white'
  },
  input: {
    borderRadius: "40px",
    border : "2px solid",
    borderColor : theme.palette.primaryCol,
    width: "30vw",
    paddingLeft : "20px",
    padding : "0",
    textAlign: "center",
    height: "40px",
    margin: theme.spacing(1),
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      height : "40px",
      width: "60vw",
    },
    backgroundColor: "white",
      
  
  },
  
  button: {
    marignLeft : theme.spacing(2),
    borderRadius : "20px",
    backgroundColor: "#00c8c8",
    color: "white",
    fontSize: "15px",
    width : "10vw",
  
    '&:hover': {
      backgroundColor: 'green',
      
    },
    
    [theme.breakpoints.down("md")]: {
      width: "10vw",
      fontSize : "0px"
    },
  },
   
  }));
  

export default function FormDialog(props) {
  
    const classes = useStyles();

    const [state, setState] = React.useState({
        email : '',
        query : ''
    });

    const handleChange = (name) => (event) => {
        event.preventDefault();
        setState({...state, [name] : event.target.value})
    }
 

  const handleClose = () => {
    props.setQuoteOpen(false);
  };

  const handleSubmit = (event) => {
      event.preventDefault();
      let apiBaseUrl = `https://api.techinsta.io/api/v1/userQuery`;
      var headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization" : localStorage.getItem("jwtToken")|| "",
      };
      var data = {
          emailAddress : state.email,
          query : state.query
      }
      axios.post(apiBaseUrl, data, {headers : headers}, {validateStatus : false})
      .then(response => {
        props.showSnackbar("Thank you for submitting", "success");
        return window.location.reload();
      })
      .catch(err => {
         props.showSnackbar("Error connecting to server", "error")
      })
  }

  return (
    <Dialog
    open={props.quoteOpen}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
    {/* <DialogTitle id="alert-dialog-slide-title">{"Sign In with Google to continue?"}</DialogTitle> */}
    <DialogContent className={classes.dialog}>
      <DialogContentText id="alert-dialog-slide-description">
        <Typography variant="h4" style={{display : 'flex', flexDirection : 'column',alignItems : "center"}}>Add your Query</Typography>
        
      {/* <GoogleLoginButton
      type="light"
     onClick={signInWithGoogle}
        /> */}
      </DialogContentText>
      
      <TextField
                className={classes.input}
                placeholder="Email Address"
                InputProps={{
                  disableUnderline: true,
                  shrink: true,
                  "&::placeholder": {
                    fontStyle: "italic",
                    textAlign: "center",
                  },
                }}
                value={state.email}
                onChange={handleChange('email')}
              />
          <TextField
                className={classes.input}
                placeholder="Your Query"
                InputProps={{
                  disableUnderline: true,
                  shrink: true,
                  "&::placeholder": {
                    fontStyle: "italic",
                    textAlign: "center",
                  },
                }}
                value={state.query}
                onChange={handleChange('query')}
              />
     <div className={classes.submitDiv}>
     <Button
            type="submit"
            
            variant="contained"
            
            className={clsx(classes.submit,classes.button)}
            startIcon = {<SendIcon />}
            onClick={handleSubmit}
          >
            Submit
          </Button>
          </div>
    </DialogContent>
    {/* <DialogActions>
   
      <Button onClick={handleClose} color="primary">
        Agree
      </Button>
    </DialogActions> */}
  </Dialog>


  );
}
