import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
    overflow: 'hidden',
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  title: {
    marginBottom: theme.spacing(14),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 100,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop : theme.spacing(8),
    borderRadius : "20px",
    backgroundColor: "#00c8c8",
    color: "white",
    fontSize: "15px",
    alignItems : "center",
    
  },
}
)
);

export default function HowItWorks(props) {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src={window.location.origin + "/images/curvylines.png"}
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Typography
          variant="h4"
          marked="center"
          className={classes.title}
          component="h2"
        >
          How it works
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}>1.</div>
                <img
                  src={window.location.origin + "/images/github.png"}
                  alt="suitcase"
                  className={classes.image}
                />
                <Typography variant="h5" align="center">
                  Upload your react github link or login via github to search
                  your repos.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}>2.</div>
                <img
                  src={
                    window.location.origin + "/images/assignment_practice.png"
                  }
                  alt="graph"
                  className={classes.image}
                />
                <Typography variant="h5" align="center">
                  You can choose to practice basics from our practice topics.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <div className={classes.number}>3.</div>
                <img
                  src="images/report_mail.png"
                  alt="clock"
                  className={classes.image}
                />
                <Typography variant="h5" align="center">
                  {"Get your thorough code report ready. "}
                  {"Report will be sent to your email address within 4 hours"}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        <Button
          size="large"
          variant="contained"
          className={classes.button}
          onClick={(e) => {
            e.preventDefault();
            window.scrollTo(0, 0);
          }}
        >
          Get started
        </Button>
        <br ></br>
        <Grid>
          <a
            href="https://www.producthunt.com/posts/tech-insta?utm_source=badge-review&utm_medium=badge&utm_souce=badge-tech-insta#discussion-body"
            target="_blank"
            style={{color : "#00c8c8"}}
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/review.svg?post_id=293882&theme=light"
              alt="Tech Insta - From create-react-app to deploy get your code assessment. | Product Hunt"
              style={{ width: "250px", height: "54px", width: 250, height: 54 }}
            />
          </a>
        </Grid>
      </Container>
    </section>
  );
}