import React from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
export default function SnackBarAlert(props) {

  
  const renderAlert = (level, message)=>{
    switch (level){
      case "success":
        return <Alert onClose={handleClose} severity="success">{message}</Alert>
      case "error":
          return <Alert onClose={handleClose} severity="error">{message}</Alert>
      default:
        return <Alert onClose={handleClose} severity="info">{message}</Alert>
    }
  }

  
  


 
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

        props.setOpen(false);
    };
    return (
      <div >
        <Snackbar open={props.open} autoHideDuration={1000} onClose={handleClose}>
          {renderAlert(props.severity, props.message)}
        </Snackbar>
      </div>
    );
}