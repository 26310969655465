import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline : "none",
      "&:focus":{
        outline: "none"
       },
    },
    paper: {
      backgroundColor: "white",
      borderRadius : '20px',
      border: '2px solid #00c8c8',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      color : theme.palette.primaryCol,
      display : 'flex',
      flexDirection : 'column', 
      alignItems : 'center'
    },
    media : {
      height : '200px',
      width : '200px',
     
      
     
    },
    text : {
        textAlign : 'center'
    },
    divider: {
        margin: `${theme.spacing.unit * 3}px 0`
      },
      button: {
        borderRadius : "20px",
        backgroundColor: "#00c8c8",
        color: "white",
        fontSize: "15px",
        alignItems : "center",
        [theme.breakpoints.down("md")]: {
          width: "10vw",
          fontSize : "10px"
        },
      },
  }));

export default function EmailDialog(props) {
  
    const classes = useStyles();

  const handleClose = () => {
    props.setThanksOpen(false);
    window.open(window.location.origin, "_self");
  };
  
  React.useEffect(() => {
    if(props.open){
      props.showSnackbar("Success", "success");
    }
    
  },[props.open])
  

  

 

  return (
    <div style={{  "&:focus":{
      outline: "none"
     } }}>
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.thanksOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.thanksOpen}>
          <div className={classes.paper}>
            
            <img src={window.location.origin + "/images/thank_you_main.svg"} alt="" className={classes.media} />
          <Divider className={classes.divider} />
          <Typography id="transition-modal-title" variant={"h6"} className={classes.text}>Thank You for choosing us.</Typography>
          <Typography id="transition-modal-title" variant={"h6"} className={classes.text}> We will send report to your email in next 4 hours</Typography>
         
          
          
          <Button variant="contained" className={classes.button}  onClick={handleClose}>
                Close
              </Button>

          
           
          </div>
        </Fade>
      </Modal>
      </div>
   
  );
}
