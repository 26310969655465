import React from 'react';
import {GithubLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import {signInWithGoogle, signInWithGithub} from '../components/firebase/firebase';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';;





const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  dialog: {
    
  backgroundColor:"#fff",
  zIndex:999
      
},
 
}));

const AlertDialogSlide=(props)=> {
  const classes=useStyles();
 


  const handleClose = () => {
    props.setOpenLogin(false);
  };

  const signIn = () => {
    
    
    signInWithGithub().then(result => {
      props.setOpenLogin(false);
      props.showSnackbar("Logged in Successfully", "success");
    })
    .catch(err => {
      props.showSnackbar("Error while logging in", "error");
    })
  }


  return (
    
<Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle id="alert-dialog-slide-title">{"Sign In with Google to continue?"}</DialogTitle> */}
        <DialogContent className={classes.dialog}>
          <DialogContentText id="alert-dialog-slide-description">
            Sign in to Continue.
            <br/><br/>
          {/* <GoogleLoginButton
          type="light"
         onClick={signInWithGoogle}
            /> */}
          </DialogContentText>
          <br/>
          <GithubLoginButton id="alert-dialog-slide-description" onClick={signIn} /> 
         
          
        </DialogContent>
        {/* <DialogActions>
       
          <Button onClick={handleClose} color="primary">
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
   
  );
}

export  default AlertDialogSlide;