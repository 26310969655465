import React from 'react';
import {GithubLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import {signInWithGoogle, signInWithGithub} from '../components/firebase/firebase';
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import axios from 'axios';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import clsx from 'clsx';
import Slide from '@material-ui/core/Slide';;





const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  dialog: {
    
  backgroundColor:"#fff",
  zIndex:999
      
},
submitDiv : {
    display : 'flex',
      flexDirection : 'column',
      alignItems : 'center',
  },
submit : {
      
      
    backgroundColor : theme.palette.primaryCol,
    color : 'white'
},
input: {
  borderRadius: "40px",
  border : "2px solid",
  borderColor : theme.palette.primaryCol,
  width: "30vw",
  paddingLeft : "20px",
  padding : "0",
  textAlign: "center",
  height: "40px",
  margin: theme.spacing(1),
  flex: 1,
  [theme.breakpoints.down("sm")]: {
    height : "40px",
    width: "60vw",
  },
  backgroundColor: "white",
    

},

button: {
  marignLeft : theme.spacing(2),
  borderRadius : "20px",
  backgroundColor: "#00c8c8",
  color: "white",
  fontSize: "15px",
  width : "10vw",

  '&:hover': {
    backgroundColor: 'green',
    
  },
  
  [theme.breakpoints.down("md")]: {
    width: "10vw",
    fontSize : "0px"
  },
},
 
}));

const AlertDialogSlide=(props)=> {
  const classes=useStyles();
 
  const [promoCode, setPromoCode] = React.useState('');

  const handleClose = () => {
    props.setPromoOpen(false);
  };

  const handleChange = (event) => {
      setPromoCode(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let apiBaseUrl = `https://api.techinsta.io/api/v1/registerBootcamp`;
    var data = {
        referralCode : promoCode
    }

    var headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Authorization" : localStorage.getItem("jwtToken")|| "",
      };
    
      axios.post(apiBaseUrl, data, {headers : headers}, {validateStatus : false})
      .then(response => {
          props.showSnackbar("Registration Successfull", "success");
          return window.location.reload();
      })
      .catch(err => {
          console.log(err);
          if(err.response.status === 401){
            props.showSnackbar(err.response.data.message, "error");
            localStorage.removeItem("jwtToken");
            localStorage.removeItem("UserData");
            localStorage.removeItem("isLogin");
            return window.location.reload();
          }
          else if(err.response.status === 412){
              props.showSnackbar(err.response.data.message, "warning");
          }
          else{
              props.showSnackbar(err.response.data.message, "error");
          }
      })
    
    
  }


  return (
    
<Dialog
        open={props.promoOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle id="alert-dialog-slide-title">{"Sign In with Google to continue?"}</DialogTitle> */}
        <DialogContent className={classes.dialog}>
          <DialogContentText id="alert-dialog-slide-description">
         
            
          {/* <GoogleLoginButton
          type="light"
         onClick={signInWithGoogle}
            /> */}
          </DialogContentText>
          
          <TextField
                className={classes.input}
                placeholder="Enter Promo Code"
                InputProps={{
                  disableUnderline: true,
                  shrink: true,
                  "&::placeholder": {
                    fontStyle: "italic",
                    textAlign: "center",
                  },
                }}
                value={promoCode}
                onChange={handleChange}
              />
          <div className={classes.submitDiv}>
     <Button
            type="submit"
            
            
            
            className={clsx(classes.submit,classes.button)}
            endIcon = {<SendIcon />}
            onClick={handleSubmit}
          >
            Submit
          </Button>
          </div>
         
          
        </DialogContent>
        {/* <DialogActions>
       
          <Button onClick={handleClose} color="primary">
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
   
  );
}

export  default AlertDialogSlide;