import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import {GithubLoginButton } from "react-social-login-buttons";
import {signInWithGithub} from '../components/firebase/firebase';
import Avatar from '@material-ui/core/Avatar';
import ButtonBase from '@material-ui/core/ButtonBase';


const useStyles = makeStyles(theme => ({
    container : {
        minHeight : '100vh',
        position: 'relative',
        maxWidth : '100%',
        backgroundColor : theme.palette.background,
        color : 'black',
        padding : theme.spacing(3)
      },
      card: {
        maxWidth: 300,
        margin: "auto",
        transition: "0.3s",
        borderRadius : "20px",
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.5)",
        "&:hover": {
          boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
        },
        [theme.breakpoints.down("md")]: {
          
          alignItems:'center'
         },
      },
      content: {
        textAlign: "left",
        padding: theme.spacing.unit * 3
      },
      media: {
        paddingTop: "50%",
        '.MuiCardMedia-img' : {
          objectFit : "contain"
        },
      },
      divider: {
        margin: `${theme.spacing.unit * 3}px 0`
      },
      login : {
          display : 'flex',
          flexDirection : 'column',
          justifyContent : 'center',
          alignItems : 'center'
      }
}))

function Bootcamps(props) {
    const classes = useStyles();
    const [jwtToken, setJwtToken] = React.useState(localStorage.getItem("jwtToken") || "");

    React.useEffect(() => {
        if(!localStorage.getItem("jwtToken")) {
            props.handleLoginOpen(true);
        }
    }, [])

    const signIn = () => {
       
        signInWithGithub().then(result => {
            props.setOpenLogin(false);
            props.showSnackbar("Logged in Successfully", "success");
          })
          .catch(err => {
            props.showSnackbar("Error while logging in", "error");
          })
    }
    return (
        <Container className={classes.container}>
        <Typography
              variant="h4"
              paragraph
              style={{ textAlign : 'center', fontWeight : 400}}
            >Bootcamps
            
            </Typography>
            <Grid
  container
  spacing={3}
  direction="row"
  // alignItems="center"
  // justify="center"
  style={{ minHeight: '30vh', paddingLeft : '2%' }}
 >
    {
        jwtToken.length > 0 ? (
            <Grid item xs={12} sm={12} md={4} lg={4}>
           
            <Card className={classes.card}>
            <CardMedia
              className={classes.media}
              image={
               "https://res.cloudinary.com/practicaldev/image/fetch/s--jSjaHNPl--/c_imagga_scale,f_auto,fl_progressive,h_900,q_auto,w_1600/https://dev-to-uploads.s3.amazonaws.com/i/szfxwsiolnt9w7gdbxek.png"
              }
            />
            {/* <ButtonBase
          
          onClick={event => { event.preventDefault();  props.handlePromoOpen(true); }}
      >  */}
          <CardActionArea onClick={(event) => {event.preventDefault(); props.handlePromoOpen(true)}}>
            <CardContent className={classes.content}>
            <Typography
                className={"MuiTypography--heading"}
                variant={"h6"}
                gutterBottom
              >
                Weather App
              </Typography>
              <Typography
                className={"MuiTypography--subheading"}
                variant={"caption"}
              >
               Create an app to fetch all cities real time weather report using React, Redux and open-source weather Api.
              </Typography>
             
              <Divider className={classes.divider} light />
              <div style={{display : 'flex', alignItems : 'center', justifyContent : 'space-around'}}>
              <Avatar src="https://avatars.githubusercontent.com/u/71818805?v=4" alt=""/>
              <Typography
                className={"MuiTypography--heading"}
                variant={"p"}
              >
               By Slava Bikhovsky
              </Typography>
              </div>
            </CardContent>
          </CardActionArea>
          {/* </ButtonBase> */}
        </Card>
        
        </Grid>
       
        
        ) : (
            <Grid container className={classes.login}>
                <Grid item xs={12} sm={6} md={4} lg={4}></Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <GithubLoginButton id="alert-dialog-slide-description" onClick={signIn} /> 
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}></Grid>
            </Grid> 
        )
    }
     {/* <Grid item xs={12} sm={12} md={4} lg={4}>
       
        <Card className={classes.card}>
        <CardMedia
          className={classes.media}
          image={
           window.location.origin + "/images/hooks.png"
          }
        />
      <CardActionArea href="assignment/6070dc3fde8b7ec847d6bbbd">
        <CardContent className={classes.content}>
        <Typography
            className={"MuiTypography--heading"}
            variant={"h6"}
            gutterBottom
          >
            React States and Hooks
          </Typography>
          <Typography
            className={"MuiTypography--subheading"}
            variant={"caption"}
          >
           Build a simple add cards to screen on click of + button and clear on click of clear
          </Typography>
          <Divider className={classes.divider} light />
        </CardContent>
      </CardActionArea>
    </Card>
   
    </Grid>
    */}
    </Grid>
      </Container>
      
    )
}

export default Bootcamps
