import React from 'react';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import { Grid } from '@material-ui/core';
import AboutUs from './AboutUs';



const useStyles = makeStyles(theme => ({
    footer: {
      marginTop : 'auto',
        backgroundColor: theme.palette.footer,
        color: "white",
        paddingBottom : '20px',
        
      },
    link : {
        color : "white",
        
    },
    headings : {
      [theme.breakpoints.down("sm")]: {
        fontSize : "10px"
      },
    }
  }));  

function Copyright() {
    const classes = useStyles();
    
    return (
      <Typography variant="body2" align="center" className={classes.headings}>
        {"Copyright © "}
        <Link color="inherit" href="https://tech-insta.io/">
          Tech Insta
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

export default function Footer() {
    const classes = useStyles();
    const [aboutOpen, setAboutOpen] = React.useState(false);
    return (
        <footer className={classes.footer}>
        <Grid container className={classes.headings}>
        <Grid item xs={4} sm={4} md={4}>
          <ul>
            <li>
        <Typography variant="subtitle2" align="left" gutterBottom>
        <Link target="_blank" href="/privacy" className={classes.link}>
                Privacy Policy
            </Link>
            
           
        </Typography>
        </li>
        <li>
        <Typography variant="subtitle2" align="left" gutterBottom>
          <Link target="_blank" href="/terms" className={classes.link}>
                Terms & Conditions
            </Link>
        </Typography>
        </li>
        <li>
        <Typography variant="subtitle2" align="left" gutterBottom>
          <Link target="_blank" href="/cancellation" className={classes.link}>
                Cancellation/Refund Policy
            </Link>
        </Typography>
        </li>
        </ul>
        
        
        </Grid>
        <Grid item xs={4} sm={4} md={4} align="medium" className={classes.link}>
        <Copyright />
        </Grid>
        <Grid item xs={0} sm={0} md={3} align="medium" >

        </Grid>
        <Grid item xs={4} sm={4} md={1} align="right">
        <ul>
          <li>
            <Typography variant="subtitle2" align="left" component="p">
              <Link onClick={e => {
                e.preventDefault();
                setAboutOpen(true);
              }} className={classes.link}>
                  About Us
              </Link>
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle2" align="left" component="p">
              <Link href="mailto:techinstaofficial@gmail.com" className={classes.link}>
                  Contact Us
              </Link>
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle2" align="left" component="p">
              <Link href="/consult" className={classes.link}>
                  Pricing
              </Link>
            </Typography>
          </li>
        </ul>
        
        </Grid>
        </Grid>
        <AboutUs aboutOpen={aboutOpen} setAboutOpen={setAboutOpen}/>
      </footer>
    )
}
