import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor:"#fff",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    backgroundColor:"#fff",
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
 

 
  const handleClose = () => {
    props.setAboutOpen(false);
  };

  return (
   
      
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title"  open={props.aboutOpen}>
        <DialogTitle style={{backgroundColor : "#fff"}} id="customized-dialog-title" onClose={handleClose}>
          About Us
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            We are a team of Develpers. Focusing on making React Js learnable for everyone.
          </Typography>
          <Typography gutterBottom>
            We are driven by the approach of solving problem one at a time and each one 
            of your code that you submit we provide you the best assessment possible.
          </Typography>
          <Typography gutterBottom>
            We also provide one to one session to enable you and your team get all their doubts cleared 
            and help you in planning your web development project
          </Typography>
          <Typography gutterBottom>
           Contact us : 
           <ul>
             <li>
               <div style={{display : "flex", alignItems : "center"}}>
               <EmailIcon style={{padding : "0px", margin : 0}}/> 
               <Typography>: techinstaofficial@gmail.com</Typography>
               </div>
               
               </li>
               <br />
             <li>
               
             <div style={{display : "flex", alignItems : "center"}}>
             <PhoneIcon />
               <Typography>: +91-7204687621</Typography>
               </div>
                </li>
                <br />
             <li>
             <div style={{display : "flex", alignItems : "center"}}>
             <LocationOnIcon />
               <Typography>: Flat No : 8, 180 N.L. HOMES, ASHRAYA LAYOUT, BENGALURU</Typography>
               </div>
                
               </li>
           </ul>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
   
  );
}
