const { default: axios } = require("axios");

module.exports = {

    refreshRepo(token) {
        var apiUrl = `${process.env.REACT_APP_AWS_URL}/api/userRepo`;
        var headers = new Headers({
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization" : token
          });
        return fetch (
            apiUrl,
            {
              method: 'PUT',
              headers: headers
            }).then(r => r.json())
            .then(r => r.data)
            .catch(err =>{
              console.log(err);
              return [];
            }
          )

    }
}