import React,{useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import ThankYou from './ThankYou';
import axios from 'axios'
const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      
    },
    paper: {
      backgroundColor: "white",
      borderRadius : '20px',
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      color : theme.palette.primaryCol
    },
    input: {
        borderRadius: "40px",
        border : "2px solid",
        borderColor : theme.palette.primaryCol,
        width: "30vw",
        paddingLeft : "20px",
        padding : "0",
        textAlign: "center",
        height: "40px",
        marginRight: theme.spacing(1),
        flex: 1,
        [theme.breakpoints.down("sm")]: {
          height : "40px",
          width: "60vw",
        },
    },
    text : {
        textAlign : 'center'
    },
    divider: {
        margin: `${theme.spacing.unit * 3}px 0`
      },
      button: {
        borderRadius : "20px",
        backgroundColor: "#00c8c8",
        color: "white",
        fontSize: "15px",
        alignItems : "center",
        [theme.breakpoints.down("md")]: {
          width: "10vw",
          fontSize : "10px"
        },
      },
  }));

export default function EmailDialog(props) {
  
    const classes = useStyles();



  const handleClose = () => {
    props.setOpen(false);
  };

  const [email, setEmail] = React.useState(JSON.parse(localStorage.getItem("UserData"))?.email || "");

  const login = localStorage.getItem("isLogin") || false;

 React.useEffect(() => {
  if(login) {
    console.log(JSON.parse(localStorage.getItem("UserData"))?.email);
    setEmail(JSON.parse(localStorage.getItem("UserData"))?.email)
  }
 },[login])

  const handleEmail = (event) => {
      setEmail(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    props.showBackdrop(true);
      if(!check) {
          props.showSnackbar('Please select privacy checkbox','error');
          props.showBackdrop(false);
          return;
      }
      var headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      let userAssignmentData;
      if(localStorage.getItem("isLogin"))
      {
       userAssignmentData={
        userId:JSON.parse(localStorage.getItem("UserData"))?.uid,
        repositoryLink:props.link || "",
        email:email,
      }
    }
      else{
        userAssignmentData={
          repositoryLink:props.link,
          email:email,
      }
      }
      console.log(userAssignmentData)
      var apiBaseUrl = `${process.env.REACT_APP_AWS_URL}/api/assignment`;
    axios.post(apiBaseUrl, userAssignmentData, {headers : headers}, {validateStatus : false})
    .then(response => {
        console.log(response);
        console.log("Link for props",props.link, " Email id is", email);
      props.setOpen(false);
      props.showBackdrop(false);
      props.handleThanksOpen(true);
        
    })
    .catch(err => {
      if(err.response.status === 422){
        console.log(err.response.data.errors);
        let errorArray = err.response.data.errors;
        let errorString = '';
        errorArray.map((error,index) => {
          if(error.msg === 'link already exist') {
            props.setOpen(false);
          }
            if(index !== errorArray.length-1) {
                errorString += error.msg +", ";
            }
            else{
                errorString += error.msg; 
            }
            
        })
        props.showBackdrop(false);
        return props.showSnackbar(errorString,"error")
    }
    
    else{
      props.showBackdrop(false);
        return props.showSnackbar("Could not connect to the server!","error")
    }
    })
      
      
      
  }

  const [check,setCheck] = React.useState(false);

  const handleCheck = (event) => {
      event.preventDefault();
      setCheck(event.target.checked)
  }

  return (
    
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        
        <Fade in={props.open}>
          <div className={classes.paper}>
            {localStorage.getItem("isLogin")?
             <Typography id="transition-modal-title" variant={"h5"} className={classes.text}>Please Confirm Your Email</Typography>
            :<Typography id="transition-modal-title" variant={"h5"} className={classes.text}>Enter Your Email Address</Typography>}
           <TextField
            autoFocus
            className={classes.input}
            id="name"
            placeholder="Email Address"
            type="email"
            InputProps={{
                disableUnderline: true,
                shrink: true,
                "&::placeholder": {
                  fontStyle: "italic",
                  textAlign: "center",
                },
              }}
            value={email}
            onChange={handleEmail}
          />
          <Divider className={classes.divider}/>
          <div>
          <Checkbox checked={check} onChange={handleCheck} name="checkedA" />
          <Typography variant={"caption"}>I agree to the <a target="_blank" href="/terms">Terms of Service</a> and <a target="_blank" href="/privacy">Privacy Policy</a></Typography>
          </div>
          <Button variant="contained" className={classes.button}  onClick={handleSubmit}>
                Submit
              </Button>

          
           
          </div>
        </Fade>
        
      </Modal>
     
   
  );
}
