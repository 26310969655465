import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Paper, Typography, Box } from '@material-ui/core';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ListAltIcon from '@material-ui/icons/ListAlt';
import CodeIcon from '@material-ui/icons/Code';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    root : {
        minHeight : '100vh',
        backgroundColor : '#fbfbf8',
        color : 'black',
        display : 'flex',
        justifyContent : 'Center',
        alignItems : 'center',
        
      },
      content : {
        minHeight : '70vh',
        minWidth : '30vw',
        backgroundColor : theme.palette.color4,
        borderRadius : '20px',
        border : '2px solid',
        elevation : 5,
        borderColor : theme.palette.primaryCol,
        
        paddingBottom : theme.spacing(2)
      },
      intro : {
        marginTop : theme.spacing(1),
        display : 'flex',
        flexDirection : 'column',
        alignItems : 'center'
      },
      pricePaper : {
          marginRight : '5%',
          marginLeft : '5%',
          minHeight : '20vh',
          border : '2px solid',
          borderRadius : '5px',
        //   borderColor : theme.palette.primaryCol,
          backgroundColor : 'white' 
      },
      price : {
          display : 'flex',
          marginTop : theme.spacing(2)
      },
      borderOne : {
        borderColor : "#78CFBF",
      },
      borderTwo : {
        borderColor : "#3EC6E0",
      },
      borderThree : {
        borderColor : "#E3536C",
      },
      paperOne : {
        borderColor : "#78CFBF",
        backgroundColor : "#78CFBF"
      },
      paperTwo : {
        borderColor : "#3EC6E0",
        backgroundColor : "#3EC6E0"
      },
      paperThree : {
        borderColor : "#E3536C",
        backgroundColor : "#E3536C"
      },

      backgroundOne : {
        backgroundColor : "#82DACA",
      },
      backgroundTwo : {
        backgroundColor : "#53CFE9",
      },
      backgroundThree : {
        backgroundColor : "#EB6379",
      },
      head : {
          display : 'flex',
          justifyContent : 'center',
          color : 'white',
          flexGrow : 1,
      },
      explain : {
          marginTop : theme.spacing(2),
        display : 'flex',
        alignItems : 'center',
        flexDirection : 'column',
        flexGrow : 1,
    },
    breakPointPaper : {
        [theme.breakpoints.down('sm')] : {
            display : 'none'
        }
    },
    breakPointSmall : {
        [theme.breakpoints.up('md')] : {
            display : 'none'
        }
    }
}))

function Consult(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = (event) => {
        event.preventDefault();
        props.handleQuoteOpen(true);
    }
    return (
        <div className={classes.root}>
          <Grid container>
            <Grid item xs={1}>

            </Grid>
            <Grid item xs={10}>
                <Paper elevation={5} className={clsx(classes.content)} spacing={3}>
                    <div className={classes.intro}>
                        <Typography variant="h4">Get in touch with us</Typography>
                        <Typography variant="h6">We help business owners take their ideas into shape.</Typography>
                        <Typography variant="h6">Get your team Expert consultation in React.</Typography>
                    </div>
                    <Grid item   className={clsx(classes.price, classes.breakPointPaper)} spacing={3}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                       <Paper elevation={5} className={clsx(classes.pricePaper, classes.borderOne)}>
                        <Typography variant="h6" className={clsx(classes.head, classes.paperOne)}>Monthly Plan</Typography>
                            <div className={clsx(classes.head, classes.backgroundOne)}>
                                <Typography variant="h6">
                                    <sup>&#8377;</sup>
                                </Typography>
                                <Typography variant="h3">
                                    <span>16,000</span>
                                </Typography>
                                <Typography variant="h6">
                                    <sub>/mo.</sub>
                                </Typography>
                            </div>
                            <div className={clsx(classes.head, classes.backgroundOne)}>
                                <Typography fontStyle="italic" variant="p">
                                    <Box fontStyle="italic">Suitable for Early stage startup</Box>
                                </Typography> 
                            </div>
                            <div className={classes.explain}>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                    <Avatar>
                                        <ListAltIcon />
                                    </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Estimate" secondary="Get complete estimation of your project on hourly basis" />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                    <Avatar>
                                        <VideoCallIcon />
                                    </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="40 Live Sessions" secondary="Get expert session for your project (Mon - Fri)" />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                    <Avatar>
                                        <GroupAddIcon />
                                    </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Exclusive Dicsord Community" secondary="Join our exclusive discord community of React Developers" />
                                </ListItem>
                                </List>
                            </div>
                            <div className={clsx(classes.head, classes.backgroundOne)}>
                               <Button className={clsx(classes.head, classes.backgroundOne)} onClick={handleOpen}>Contact Us</Button>
                            </div> 
                       </Paper> 
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                       <Paper elevation={5} className={clsx(classes.pricePaper, classes.borderTwo)}>
                        <Typography variant="h6" className={clsx(classes.head, classes.paperTwo)}>Weekend Plan</Typography>
                            <div className={clsx(classes.head, classes.backgroundTwo)}>
                                <Typography variant="h6">
                                    <sup>&#8377;</sup>
                                </Typography>
                                <Typography variant="h3">
                                    <span>4,000</span>
                                </Typography>
                                <Typography variant="h6">
                                    <sub>/mo.</sub>
                                </Typography>
                            </div>
                            <div className={clsx(classes.head, classes.backgroundTwo)}>
                                <Typography variant="p">
                                    <Box fontStyle="italic">Suitable for Soloprenuers</Box>
                                </Typography> 
                            </div>
                            <div className={classes.explain}>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                    <Avatar>
                                        <ListAltIcon />
                                    </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Estimate" secondary="Get complete estimation of your project on hourly basis" />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                    <Avatar>
                                        <VideoCallIcon />
                                    </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="8 Live Sessions" secondary="Get expert session for your project (Sat - Sun)" />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                    <Avatar>
                                        <GroupAddIcon />
                                    </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Exclusive Dicsord Community" secondary="Join our exclusive discord community of React Developers" />
                                </ListItem>
                                </List>
                            </div>
                            <div className={clsx(classes.head, classes.backgroundTwo)}>
                               <Button className={clsx(classes.head, classes.backgroundTwo)} color="inherit" onClick={handleOpen}>Contact Us</Button>
                            </div> 
                       </Paper> 
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                       <Paper elevation={5} className={clsx(classes.pricePaper, classes.borderThree)}>
                        <Typography variant="h6" className={clsx(classes.head, classes.paperThree)}>Module Wise</Typography>
                            <div className={clsx(classes.head, classes.backgroundThree)}>
                                <Typography variant="h6">
                                    <sup>&#8377;</sup>
                                </Typography>
                                <Typography variant="h3">
                                    <span>--</span>
                                </Typography>
                                <Typography variant="h6">
                                    <sub>/mo.</sub>
                                </Typography>
                            </div>
                            <div className={clsx(classes.head, classes.backgroundThree)}>
                                <Typography fontStyle="italic" variant="p">
                                    <Box fontStyle="italic">Stuck at a module</Box>
                                </Typography> 
                            </div>
                            <div className={classes.explain}>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                    <Avatar>
                                        <ListAltIcon />
                                    </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Estimate" secondary="Get complete estimation of your module on hourly basis" />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                    <Avatar>
                                        <VideoCallIcon />
                                    </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Exclusive Code consultation" secondary="Get exclusive plug and play code for your module" />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                    <Avatar>
                                        <CodeIcon />
                                    </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Premium reusable model" secondary="We assure quality of code for any specific needs viz. Authentication, Api calls,FireBase Integration" />
                                </ListItem>
                                </List>
                            </div>
                            <div className={clsx(classes.head, classes.backgroundThree)}>
                               <Button className={clsx(classes.head, classes.backgroundThree)} variant="contained" onClick={handleOpen}>Contact Us</Button>
                            </div> 
                       </Paper> 
                    </Grid>
                    </Grid>
                <Grid item className={classes.intro}>
                    
                    <Button variant="outlined" color="inherit" className={classes.breakPointSmall}>Switch to Desktop Site to view this</Button>
                </Grid>
                </Paper>
            </Grid>
            <Grid item xs={1}>
                
            </Grid>
          </Grid>
          
        </div>
    )
}

export default Consult
