import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { fade, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import GitHubIcon from '@material-ui/icons/GitHub';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Typewriter from 'typewriter-effect';
import EmailDialog from './EmailDialog';
import UseDebounce from '../library/Debounce';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CachedIcon from '@material-ui/icons/Cached';
import IconButton from '@material-ui/core/IconButton';
import {refreshRepo} from '../apis/RefreshRepo';
import SearchField from '../commons/SearchField';
import Tooltip from '@material-ui/core/Tooltip';



const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiAutocomplete-listbox': {
        backgroundColor: "white",
        elevation: 5
    },
    '.MuiAutocomplete-noOptions' : {
        backgroundColor: "white",
        elevation: 5
    }
},
  root: {
    minHeight: "600px",
    maxWidth: "100%",
    width: "100%",
    backgroundColor : theme.palette.secondary.light,
    backgroundPositionX: "50%",
    backgroundPositionY: "center",
    color: "black",
  },
  center: {
    textAlign: "center",
    
    [theme.breakpoints.up("md")]: {
      "display" : "flex",
      "flexDirection" : "column",
      
      "marginTop" : "20px"
    },
    spacing: theme.spacing(2),
  },
  textTypography: {
    paddingTop : theme.spacing(5),
    textAlign: "left",
    marginLeft : "100px",
    justifyContent:'center',
    alignItems:'center',
    [theme.breakpoints.down("md")]: {
     textAlign : "center",
     marginLeft : "5px",
     justifyContent:'center'
    }, [theme.breakpoints.down("sm")]: {
      textAlign : "center",
      padding:'10%',
      marginLeft : "5px",
      justifyContent:'center'
     },
    spacing: theme.spacing(4),
  },
  button: {
    borderRadius : "20px",
    backgroundColor: "#00c8c8",
    color: "white",
    fontSize: "15px",
    alignItems : "center",
    textAlign:'center',
    justifyContent:'center',
    marginLeft : theme.spacing(25),
    marginTop:theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      width: "10vw",
      fontSize : "0px",
      marginTop:theme.spacing(1),
      marginLeft : theme.spacing(0),
      alignItems : "center",
      justifySelf:'center',
    },
  },
  disabledButton: {
    backgroundColor: "grey",
  },
  inputType : {
    height: "40px",
    width: "30vw",
    [theme.breakpoints.down("sm")]: {
      height : "40px",
      width: "60vw",
    },
  },
  rootInput : {
    display : 'flex',
    flexDirection : 'column',
    flex : 0.7,
    [theme.breakpoints.down("md")]: {
        flex : 1,
      },
    marginRight : theme.spacing(2)
},
  input: {
    borderRadius: "40px",
    border : "2px solid",
    borderColor : theme.palette.primaryCol,
    width: "70%",
    paddingLeft : "20px",
    padding : "0",
    textAlign: "center",
    height: "40px",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  
    [theme.breakpoints.down("sm")]: {
      height : "40px",
      width: "90%",
    },
    backgroundColor: "white",
  
  },
  search: {
    width: "30vw",
    
    border :'2px solid',
    borderColor : theme.palette.primaryCol,
    
    padding :"0",
    backgroundColor : 'white',
    [theme.breakpoints.down("sm")]: {
      
      width: "60vw",
    },
    flex : 1
  },
  itemList : {
    height: "40px",
    overflow : "hidden"
    
    
    
  },
  search1:{
    display:'flex',
    margin:'5px',
    alignItems:'center',
  },
  iconButton : {
    "padding" : 0, 
    backgroundColor : "#00c8c8", 
    color : "white",
    width:'35px',
    height:'35px',
    alignItems:'center'
  },
  iconButton2 : {
    padding : 0, 
    backgroundColor : "#00c8c8", 
    width:'35px',
    height:'35px',
    alignItems:'center',
    marginTop: theme.spacing(1),
    justifyContent : 'center'
  }
}));

function HomePageStepper(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [login,setLogin] = React.useState(localStorage.getItem('isLogin' || false));
  const [link, setLink] = React.useState('');
  const [results, setResults] = React.useState([]);

  
  const handleClickOpen = () => {
    let link1 = link;
    
    if(link1.match(/^(([A-Za-z0-9]+@|http(|s)\:\/\/)|(http(|s)\:\/\/[A-Za-z0-9]+@))([A-Za-z0-9.]+(:\d+)?)(?::|\/)([\d\/\w.-]+?)(\.git){1}$/)) {
      setOpen(true);
      return;
    }
    props.showSnackbar("Please Enter Valid Git Url", "error");
  };

  

  

  const handleChange = (event) => {
    setLink(event.target.value)
  }

  const handleLogin = (event) => {
    event.preventDefault();
    props.handleLoginOpen(true);
  }

  

    const handleRefresh = (event) => {
      event.preventDefault();
      props.showBackdrop(true)
      refreshRepo(localStorage.getItem("jwtToken") || "")
      .then(result => {
        
        if(result.length > 0) {
          props.showBackdrop(false);
          props.showSnackbar("Urls updated successfully", "success");
        }
        else{
          props.showBackdrop(false);
          props.showSnackbar("Error updating urls", "error");
        }
      })
      .catch(err => {
        props.showBackdrop(false);
          props.showSnackbar("Error updating urls", "error");
      })
    }

  
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={7}
            className={classes.center}
            spacing={1}
          >
            

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              spacing={1}
              className={classes.textTypography}
            >
              <Typography variant="h4" >React JS made Easy</Typography>
              <Typography variant="h4" style={{color : "#00c8c8"}}><Typewriter
  options={{
    strings: ['Code for Quality.', 'Strive for Perfection.', 'Become a Pro.'],
    autoStart: true,
    loop: true,
  }}
  style={{ color:"blue" }}
/></Typography>
              <Typography variant="h6">Upload your React <GitHubIcon /> link below and get your code assessment</Typography>
              
              {
                !login ? (
                  <>
                  <Grid
                  item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              spacing={2}
                  >
                    
                    <TextField
                className={classes.input}
                placeholder="Upload link"
                InputProps={{
                  disableUnderline: true,
                  shrink: true,
                  "&::placeholder": {
                    fontStyle: "italic",
                    textAlign: "center",
                  },
                }}
                value={link}
                onChange={handleChange}
              />
               <Tooltip title="Login via github" aria-label="add">
               <IconButton className={classes.iconButton2} 
              aria-label={"Login via github"}
              role="alert"
              onClick={handleLogin}
              >
                <GitHubIcon />
              </IconButton>
                 </Tooltip>     
              <br/>
            </Grid>
            </>
                ) :  (
               <>
                 <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              spacing={2}
             className={classes.search1}
            >
                  <SearchField link={link} setLink={setLink} results={results} setResults={setResults} from='stepper'/>
                 
                <IconButton className={classes.iconButton} style={results.length ? {"display" : "none"} : {}} 
                aria-label="cache"
                hidden={results.length > 0 ? true : false}
                onClick={handleRefresh}
                >
                <CachedIcon />
              </IconButton><br/>
            
              </Grid>
             
                 </>
                
                  )
                
              }

<Button variant="contained" className={classes.button} startIcon={<CloudUploadIcon />} onClick={handleClickOpen}>
                Upload
              </Button>
             
           
              
           
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
              
           <img src="images/background.svg" alt=""/>
            
          </Grid>
        </Grid>
      </div>
      <EmailDialog open={open} setOpen={setOpen} link={link} handleThanksOpen={props.handleThanksOpen} showBackdrop={props.showBackdrop} showSnackbar={props.showSnackbar} repoLink={link}/>
    </div>
  );
}



export default HomePageStepper;
