import React from 'react'

function Privacy() {
    return (
        <div style={{height : '100vh'}}>
             <object data="docs/terms_of_service.pdf" type="application/pdf" width="100%" height="100%">
                
        </object>
        </div>
    )
}

export default Privacy
