import React from 'react';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from "@material-ui/core/TextField";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StarIcon from '@material-ui/icons/Star';
import Link from '@material-ui/core/Link';
import GitHubIcon from '@material-ui/icons/GitHub';
import axios from 'axios';
import {refreshRepo} from '../apis/RefreshRepo';
import UseDebounce from '../library/Debounce';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CachedIcon from '@material-ui/icons/Cached';
import IconButton from '@material-ui/core/IconButton';
import SearchField from '../commons/SearchField';


const useStyles = makeStyles(theme => ({
    '@global': {
        '.MuiAutocomplete-listbox': {
            backgroundColor: "white",
            elevation: 5
        },
        '.MuiAutocomplete-noOptions' : {
            backgroundColor: "white",
            elevation: 5
        }
    },
    root : {
        backgroundColor : theme.palette.secondary.light,
        minHeight : '100vh',
        
    },
    container : {
        padding : theme.spacing(4),
        
    },
    chip : {
        display : 'flex',
        margin : theme.spacing(1),
        [theme.breakpoints.down("md")]: {
            display: 'grid',
            gridTemplateColumns:'120px 110px'
          },
    },
    wrapChip : {
       margin :  theme.spacing(1),
       overflow : 'hidden'
    },
    media : {
        height : '50vh',
        width : '40vw',
        border : '2px solid',
        borderRadius : '10px',
        [theme.breakpoints.down("md")]: {
            height : "50vh",
            width: "100vw",
          },
        
    },
    input: {
        borderRadius: "40px",
        border : "2px solid",
        borderColor : theme.palette.primaryCol,
        width: "30vw",
        paddingLeft : "20px",
        padding : "0",
        textAlign: "center",
        height: "40px",
        margin: theme.spacing(1),
        flex: 1,
        [theme.breakpoints.down("sm")]: {
          height : "40px",
          width: "60vw",
        },
        backgroundColor: "white",
          
      
      },
      button: {
        marignLeft : theme.spacing(2),
        borderRadius : "20px",
        backgroundColor: "#00c8c8",
        color: "white",
        fontSize: "15px",
        width : "10vw",
        
        [theme.breakpoints.down("md")]: {
          width: "10vw",
          fontSize : "0px"
        },
      },
      center : {
        textAlign : 'center',
        fontSize : '40px'
      },
      alignMedia : {
          display : 'flex',
          justifyContent : 'center'
      },
      formCard : {
          display : 'flex',
          flexDirection : 'column',
          margin : theme.spacing(2),
          backgroundColor : "#fff",
          borderRadius : "20px",
          border : '2px solid #00c8c8',
          textAlign : 'center'
      },
      insideDiv : {
          display : 'flex',
          flexDirection : 'column',
          alignItems : 'center',
          margin : theme.spacing(2)
      }

})
)

function Assignment(props) {
    const classes = useStyles();
    const assignmentId = props.match.params.id || "";
    const chips = [
        {name : 'Material UI', link:'https://material-ui.com/'},
        {name : 'Mui Icons', link: 'https://material-ui.com/components/material-icons/'},
        {name : 'React Hooks', link : 'https://reactjs.org/docs/hooks-intro.html'},
        {name : 'Grid', link : 'https://material-ui.com/components/grid/'},
        {name : 'Make Styles', link : 'https://material-ui.com/styles/basics/'}
    ];
    const login = localStorage.getItem("isLogin") || false;

   
    const [state, setState] = React.useState({
      link : '',
      email : ''
    });
    const [link, setLink] = React.useState('');
    const [results, setResults] = React.useState([]);
    React.useEffect(() => {
        if(login) {
        
          setState({...state,email:JSON.parse(localStorage.getItem("UserData"))?.email})
        }
       },[login])
    const handleChange = (name) => (event) => {
        
        setState({...state, [name] : event.target.value})
    }

    const handleLogin = (event) => {
      event.preventDefault();
      props.handleLoginOpen(true);
    }

    const handleLinkChange = (event) => {
      event.preventDefault();
      setLink(event.target.value)
    }
   

    const handleRefresh = (event) => {
      event.preventDefault();
      props.showBackdrop(true)
      refreshRepo(localStorage.getItem("jwtToken") || "")
      .then(result => {
        
        if(result.length > 0) {
          props.showBackdrop(false);
          props.showSnackbar("Urls updated successfully", "success");
        }
        else{
          props.showBackdrop(false);
          props.showSnackbar("Error updating urls", "error");
        }
      })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let userAssignmentData;
        var headers = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          };
      if(localStorage.getItem("isLogin"))
      {
       userAssignmentData={
        userId:JSON.parse(localStorage.getItem("UserData"))?.uid,
        repositoryLink:link,
        email:state.email,
        assignmentId : assignmentId
      }
    }
      else{
        userAssignmentData={
          repositoryLink:link,
          email:state.email,
          assignmentId : assignmentId
      }
      }
      console.log(userAssignmentData)
      var apiBaseUrl = `${process.env.REACT_APP_AWS_URL}/api/assignment`;
    axios.post(apiBaseUrl, userAssignmentData, {headers : headers}, {validateStatus : false})
    .then(response => {
        console.log(response);
      props.handleThanksOpen(true);
        
    })
    .catch(err => {
        if(err.response.status === 422){
            console.log(err.response.data.errors);
            let errorArray = err.response.data.errors;
            let errorString = '';
            errorArray.map((error,index) => {
                if(index !== errorArray.length-1) {
                    errorString += error.msg +", ";
                }
                else{
                    errorString += error.msg; 
                }
                
            })
            return props.showSnackbar(errorString,"error")
        }
        
        else{
            return props.showSnackbar("Could not connect to the server!","error")
        }

        
    })
       
        console.log("Link is", state.link, " Email is", state.email);
    }
    return (
        <div className={classes.root}>
            <Grid container className={classes.container}>
                
            
            {/* First component */}
            
                <Grid item xs={12} sm={12} md={12} lg={6} spacing={2}>
                {/* Heading */}
                <Typography variant={"h4"} className={classes.center}>Card Generator</Typography>
                {/* Video Gif */}
                <div className={classes.alignMedia}>
                <img src={window.location.origin + "/images/assignment1_final.gif"} alt="React Hooks and State" className={classes.media}/>
                </div>
                
                <List  aria-label="Content">
                    <ListItem >
                        <ListItemIcon>
                        <StarIcon />
                        </ListItemIcon>
                        <Typography variant={"h6"}>
                            Create a react project displaying two buttons on top right  <Button variant="contained" size="small" color="primary" startIcon={<AddIcon />}>Add </Button>&nbsp;
                            and  &nbsp;<Button variant="contained" size="small" color="secondary" startIcon={<DeleteIcon />}>Clear</Button>.
                        </Typography>
                    </ListItem>
                    <ListItem >
                        <ListItemIcon>
                        <StarIcon />
                        </ListItemIcon>
                        <Typography variant={"h6"}>
                            On Click of Add button a Card with 2px solid border should display on the screen with its serial number in the center.
                        </Typography>
                    </ListItem>
                    <ListItem >
                    <ListItemIcon>
                        <StarIcon />
                        </ListItemIcon>
                        <Typography variant={"h6"}>
                            There should be 4 cards in a row in large screen and 2 in mobile view.
                         </Typography>
                    </ListItem>
                    <ListItem >
                    <ListItemIcon>
                        <StarIcon />
                        </ListItemIcon>
                        <Typography variant={"h6"}>
                            On Click of clear all cards should disapper.
                        </Typography>
                    </ListItem>
                   
                </List>
               
                {/* Description */}
                
               
                
               
                {/* Chips for Reference */}
                <div className={classes.chip}>
                    {
                        chips.map((chip,index) => (
                            <Link target="_blank" href={chip.link} className={classes.wrapChip}  color="inherit">
                                <Chip id={"item_"+index} label={chip.name} variant="outlined" />
                            </Link>
                           
                        ))
                    }
                </div>
               
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={1} spacing={2}>
                <Divider orientation="vertical" />
            </Grid>
            {/* Second Component */}
            <Grid item xs={12} sm={12} md={12} lg={5} spacing={2} >
                {/* Add Link Field */}
                <Paper elevation={3} className={classes.formCard} spacing={2}>
                    <div className={classes.insideDiv}>
                <Typography variant={"h4"}>Add your <GitHubIcon /> Link</Typography>
                {/* Email Address */}
                {!login ? (
                     <TextField
                     className={classes.input}
                     placeholder="Add Github link"
                     InputProps={{
                       disableUnderline: true,
                       shrink: true,
                       "&::placeholder": {
                         fontStyle: "italic",
                         textAlign: "center",
                       },
                     }}
                     value={link}
                     onChange={handleLinkChange}
                   />
                ) : (
                    
                  <SearchField link={link} setLink={setLink} results={results} setResults={setResults} from={'assignment'}/>
                
                )}
               
               <TextField
                className={classes.input}
                placeholder="Email Address"
                InputProps={{
                  disableUnderline: true,
                  shrink: true,
                  "&::placeholder": {
                    fontStyle: "italic",
                    textAlign: "center",
                  },
                }}
                value={state.email}
                onChange={handleChange('email')}
              />
                {/* Submit Button */}
                <Grid style={{display : 'flex'}} spacing={2}>
                
              
                
              {!login ? (
                   <Grid item xs={12} sm={12} md={12} lg={12} style={{"display" : "flex"}}>
                   <Grid item xs={4} sm={4} md={4} lg={4}>
                   <Button variant="contained" className={classes.button} startIcon={<CloudUploadIcon />} onClick={handleSubmit}>
                   Upload
                 </Button> 
                   </Grid>
                   <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                  
                   <Grid item xs={4} sm={4} md={4} lg={4}>
                 <Button variant="contained" className={classes.button} startIcon={<GitHubIcon />} onClick={handleLogin}>
                 Login
               </Button>
                 </Grid>
                
               </Grid>
              ) : (
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{"display" : "flex"}}>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                <Button variant="contained" className={classes.button} startIcon={<CloudUploadIcon />} onClick={handleSubmit}>
                Upload
              </Button> 
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
               
                <Grid item xs={4} sm={4} md={4} lg={4}>
              <Button variant="contained" className={classes.button} startIcon={<CachedIcon />} onClick={handleRefresh}>
              Refresh
            </Button>
              </Grid>
             
            </Grid>
              )}
                </Grid>
               
              </div>
                </Paper>
               
            </Grid>
             </Grid>
        </div>
    )
}

async function getGithubSearch(search){
        
    var queryString = `string=${search}&size=5`
    
    console.log("Query string is", queryString);
     return fetch (
      `${process.env.REACT_APP_AWS_URL}/api/userRepo?${queryString}`,
      {
        method: 'GET',
        headers: new Headers({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Authorization" : localStorage.getItem("jwtToken") || ""
        })
      }).then(r => r.json())
      .then(r => r.data)
      .catch(err =>{
        console.log(err);
        return [];
      }
    )
    
  }
  

export default Assignment;
