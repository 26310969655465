import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import HomePageStepper from '../layouts/HomePageStepper';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import HowItWorks from '../layouts/HowItWorks';
import Divider from "@material-ui/core/Divider";
import { Redirect } from "react-router";
import Bootcamps from '../layouts/Bootcamps';

function Copyright() {
  return (
    <Typography variant="body2"  align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://tech-insta.io/">
        Tech Insta
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root : {
    flexGrow: 1,
    backgroundColor : '#fbfbf8',
    color : 'black'
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 0, 0),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  footer: {
    backgroundColor: theme.palette.footer,
    padding: theme.spacing(6),
    color : "white",
  },
  appBar: {
    position: 'relative',
    backgroundColor : theme.palette.background,
  },
  container : {
    position: 'relative',
    maxWidth : '100%',
    backgroundColor : theme.palette.background,
    color : 'black',
    padding : theme.spacing(3)
  },
  containernobg : {
    position: 'relative',
    maxWidth : '100%',
    padding : theme.spacing(3)
  },
  gridList: {
    flexWrap: 'nowrap',
  },
  title: {
    color: "theme.palette.primary.light",
    textAlign : "center"
  },
  center : {
    textAlign : "center",
    color : "white"
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  savebtn:{
    float :'right',
    display: 'flex',
    justify: 'flex-end',
  },
  card: {
    maxWidth: 300,
    margin: "auto",
    transition: "0.3s",
    borderRadius : "20px",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.5)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
    },
    [theme.breakpoints.down("md")]: {
      
      alignItems:'center'
     },
  },
  content: {
    textAlign: "left",
    padding: theme.spacing.unit * 3
  },
  media: {
    paddingTop: "30%",
    '.MuiCardMedia-img' : {
      objectFit : "contain"
    },
  },
  divider: {
    margin: `${theme.spacing.unit * 3}px 0`
  },
  heading: {
    fontWeight: "bold"
  },
  subheading: {
    lineHeight: 1.8
  },
}));



export default function Home(props) {
  const classes = useStyles();

  
  const [open, setOpen] = React.useState(false);

  const [profileFlag, setProfileFlag] = React.useState(false);

  
  const handleClose = () => {
    setOpen(false);
  };

//   React.useEffect(() => {
//     props.showBackdrop(true);
//     console.log(localStorage.getItem("profile-updated"));
//     if(localStorage.getItem("profile-updated") === "false") {
//       setOpen(true);
//       setProfileFlag(true);
//     }
//     else {
//       setProfileFlag(false);
//       setOpen(false);
//     }
//     props.showBackdrop(false);
//   }, []);

  const handleUpdateProfile = () => {
    setOpen(true);
  }

 
  return (
    <React.Fragment>
      <main className={classes.root}>
        {/* Hero unit */}
        <div className={classes.heroContent}>
            <HomePageStepper loginFlag={true} profileFlag={profileFlag} 
            handleThanksOpen={props.handleThanksOpen} 
            showBackdrop={props.showBackdrop}
            showSnackbar={props.showSnackbar} handleLoginOpen={props.handleLoginOpen}/>
        </div>
        <Container className={classes.container}>
        <Typography
              variant="h4"
              paragraph
              style={{ textAlign : 'center', fontWeight : 400}}
            >Practice Topics
            
            </Typography>
            <Grid
  container
  spacing={3}
  direction="row"
  // alignItems="center"
  // justify="center"
  style={{ minHeight: '30vh', paddingLeft : '2%' }}
 >
     <Grid item xs={12} sm={12} md={4} lg={4}>
       
        <Card className={classes.card}>
        <CardMedia
          className={classes.media}
          image={
           window.location.origin + "/images/hooks.png"
          }
        />
      <CardActionArea href="assignment/6070dc3fde8b7ec847d6bbbd">
        <CardContent className={classes.content}>
        <Typography
            className={"MuiTypography--heading"}
            variant={"h6"}
            gutterBottom
          >
            React States and Hooks
          </Typography>
          <Typography
            className={"MuiTypography--subheading"}
            variant={"caption"}
          >
           Build a simple add cards to screen on click of + button and clear on click of clear
          </Typography>
          <Divider className={classes.divider} light />
        </CardContent>
      </CardActionArea>
    </Card>
   
    </Grid>
   
    </Grid>
      </Container>
      <HowItWorks />
      {/* <Bootcamps showSnackbar={props.showSnackbar}/> */}
      </main>
      {/* Footer */}
      {/* End footer */}
   
    </React.Fragment>
  );
}
