import React from 'react';
import './App.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';

import Header from './layouts/Header';
import Home from './components/Home';
import history from './history';
import ThankYou from './layouts/ThankYou';
import SnackBarAlert from './commons/SnackBarAlert';
import BackDrop from './commons/BackDrop';
import Assignment from './components/Assignment';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Cancellation from './components/Cancellation';
import LoginPopup from './commons/LoginPopup';
import Consult from './components/Consult';
import QuotePopup from './commons/QuotePopup';
import Bootcamps from './layouts/Bootcamps';
import PromoPopup from './commons/PromoPopup';

const theme = createMuiTheme({
  palette:{
    background : '#fff',
    footer : '#666',
    primaryCol : "#00c8c8",
    color3 : '#373F51',
    color4 : '#eeeeee',
    color5 : '#ffffff',
    secondary: {
      light: '#fff5f8',
      main: '#ff3366',
      dark: '#e62958',
    },
  },
  typography: {
    "fontFamily": "Montserrat",
   }
});


function App() {

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setsnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [quoteOpen, setQuoteOpen] = React.useState(false);
  const [promoOpen, setPromoOpen] = React.useState(false);

  const handleQuoteOpen = (value) => {
    setQuoteOpen(value);
  }

  const handlePromoOpen = (value) => {
    setPromoOpen(value);
  }


  const showSnackbar =  (message, severity)=> {
    setSnackbarOpen(true)
    setsnackbarMessage(message)
    setSnackbarSeverity(severity)
    
    return false
  }

  const [thanksOpen, setThanksOpen] = React.useState(false);

  const handleThanksOpen = (value) => {
    setThanksOpen(value);
  }

  const showBackdrop = (val)=>{
    setBackdropOpen(val)
  }

  const handleLoginOpen = (value) => {
    setLoginOpen(value);
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Router history={history}>
      <ThankYou thanksOpen={thanksOpen} setThanksOpen={setThanksOpen} showSnackbar={showSnackbar}/>
      <QuotePopup quoteOpen={quoteOpen} setQuoteOpen={setQuoteOpen} showSnackbar={showSnackbar}/>
      <PromoPopup promoOpen={promoOpen} setPromoOpen={setPromoOpen} showSnackbar={showSnackbar}/>
      <SnackBarAlert
          open={snackbarOpen} 
          setOpen={setSnackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          showSnackbar={showSnackbar}
        />
        <BackDrop open={backdropOpen} />
        <LoginPopup open={loginOpen} setOpenLogin={setLoginOpen} showBackdrop={showBackdrop} showSnackbar={showSnackbar}/>
        <Switch>
        <Route exact path="/assignment/:id" render={(props) => (
          <Header  content={<Assignment {...props} handleLoginOpen={handleLoginOpen}  handleThanksOpen={handleThanksOpen} showSnackbar={showSnackbar} showBackdrop={showBackdrop}/>} />
        )} />
          
          
          <Route path="/privacy">
          <Header content={<Privacy  handleThanksOpen={handleThanksOpen} showSnackbar={showSnackbar} showBackdrop={showBackdrop}/>} />
          </Route>
          <Route path="/bootcamps">
            <Header content={<Bootcamps handleLoginOpen={handleLoginOpen}  handleThanksOpen={handleThanksOpen} handlePromoOpen={handlePromoOpen} showSnackbar={showSnackbar} showBackdrop={showBackdrop}/>} />
          </Route>
          <Route path="/consult">
          <Header content={<Consult  handleThanksOpen={handleThanksOpen} showSnackbar={showSnackbar} showBackdrop={showBackdrop} handleQuoteOpen={handleQuoteOpen}/>} />
          </Route>
          <Route path="/terms">
          <Header content={<Terms  handleThanksOpen={handleThanksOpen} showSnackbar={showSnackbar} showBackdrop={showBackdrop}/>} />
          </Route>
          <Route path="/cancellation">
          <Header content={<Cancellation  handleThanksOpen={handleThanksOpen} showSnackbar={showSnackbar} showBackdrop={showBackdrop}/>} />
          </Route>
          <Route path="/">
          <Header content={<Home  handleThanksOpen={handleThanksOpen} handleLoginOpen={handleLoginOpen} showSnackbar={showSnackbar} showBackdrop={showBackdrop}/>} />
          </Route>
          
        </Switch>
        
      </Router>
    
     
    
    </MuiThemeProvider>
  );
}

export default App;
